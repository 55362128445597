import React, { Component } from "react"
import { Helmet } from "react-helmet"
import { FormattedMessage } from "react-intl"

import Header from "../components/Header"

import "./thanks.css"

import boat from "../images/boat.svg"

class Thanks extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <meta name="robots" content="noindex, follow" />
        </Helmet>
        <Header>
          <FormattedMessage id="thanks_title" tagName="h1" />
        </Header>
        <main className="thanks">
          <img src={boat} alt="raft" />
          <br />
          <FormattedMessage id="thanks" />
        </main>
      </div>
    )
  }
}

export default Thanks
